import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';
import { Store } from '../Store';

const TransactionRequests = () => {
  const { state } = useContext(Store);
  const { userInfo } = state;

  const [amount, setAmount] = useState('');
  const [transactionType, setTransactionType] = useState('contribution');
  const [message, setMessage] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState('');

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        // Fetch the groups where the user is a member
        const response = await axios.get('/api/group', {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });

        const userGroups = response.data.groups;
        setGroups(userGroups);

        if (userGroups.length > 0) {
          setSelectedGroupId(userGroups[0]._id); // Select the first group by default
        }
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    // Call the fetchGroups function when the component mounts
    fetchGroups();
  }, [userInfo]);

  const handleTransactionRequest = async (e) => {
    e.preventDefault();
    try {
      if (!selectedGroupId) {
        setMessage('Please select a group');
        return;
      }

      const response = await axios.post('/api/transactions/request', {
        groupId: selectedGroupId,
        recipientId: groupCode, // Use groupCode as recipientId if not a member
        amount,
        transactionType,
      });

      setMessage(response.data.message || 'Transaction request sent successfully');
    } catch (error) {
      setMessage(`Error sending transaction request: ${error.message}`);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-6 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-center">Transaction Requests</h2>
      <Form onSubmit={handleTransactionRequest}>
        <Form.Group controlId="amount">
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount"
            required
          />
        </Form.Group>
        <Form.Group controlId="transactionType">
          <Form.Label>Transaction Type</Form.Label>
          <Form.Control
            as="select"
            value={transactionType}
            onChange={(e) => setTransactionType(e.target.value)}
          >
            <option value="contribution">Contribution</option>
            <option value="withdrawal">Withdrawal</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="groupCode">
          <Form.Label>Group Code (If not a member)</Form.Label>
          <Form.Control
            type="text"
            value={groupCode}
            onChange={(e) => setGroupCode(e.target.value)}
            placeholder="Enter group code"
          />
        </Form.Group>
        <Form.Group controlId="selectedGroupId">
          <Form.Label>Select Group</Form.Label>
          <Form.Control
            as="select"
            value={selectedGroupId}
            onChange={(e) => setSelectedGroupId(e.target.value)}
          >
            {groups.map((group) => (
              <option key={group._id} value={group._id}>
                {group.groupName}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button variant="primary" type="submit" className="w-full">
          Send Transaction Request
        </Button>
        {message && <Alert variant="success" className="mt-2 text-center">{message}</Alert>}
      </Form>
    </div>
  );
};

export default TransactionRequests;
