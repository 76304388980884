import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const MomoIntegration = ({ group }) => {
  const [amount, setAmount] = useState('');
  const [transactionType, setTransactionType] = useState('contribution');
  const [message, setMessage] = useState('');

  const handleMomoTransaction = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/transaction/collect', {
        groupId: group._id,
        amount,
        transactionType,
      });
      console.log(response);
      setMessage(response.data.message || 'Transaction completed successfully');
    } catch (error) {
      setMessage(`Error processing transaction: ${error.message}`);
    }
  };

  return (
    <Container className="max-w-md mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-semibold mb-4">MTN MoMo Integration</h2>
      <Form onSubmit={handleMomoTransaction}>
        <Form.Group controlId="amount">
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="transactionType">
          <Form.Label>Transaction Type</Form.Label>
          <Form.Control
            as="select"
            value={transactionType}
            onChange={(e) => setTransactionType(e.target.value)}
          >
            <option value="contribution">Contribution</option>
            <option value="withdrawal">Withdrawal</option>
          </Form.Control>
        </Form.Group>
        <Button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
        >
          Process Transaction
        </Button>
        <p className="mt-2 text-center text-red-500">{message}</p>
      </Form>
    </Container>
  );
};

export default MomoIntegration;
