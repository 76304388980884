import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Alert, ListGroup } from 'react-bootstrap';

const Vote = () => {
  const [group, setGroup] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { groupId } = useParams();

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const response = await axios.get(`/api/group/${groupId}`);
        setGroup(response.data);
      } catch (error) {
        console.error('Error fetching group data:', error.response?.data);
        setErrorMessage('Error fetching group data. Please try again.');
      }
    };

    fetchGroupData();
  }, [groupId]);

  const handleVote = async () => {
    try {
      const response = await axios.post(`/api/group/${groupId}/vote`);
      console.log('Vote recorded:', response.data);
      setGroup(response.data.group);
    } catch (error) {
      console.error('Error voting:', error.response?.data);
      setErrorMessage('Error voting. Please try again.');
    }
  };

  return (
    <div>
      {group && (
        <div>
          <h1 className="text-3xl font-bold">Vote for Administrators</h1>
          <p>Group Name: {group.groupName}</p>

          {/* Display the list of group members using ListGroup */}
          <h2 className="text-xl font-semibold mt-4">Group Members</h2>
          <ListGroup>
            {group.members.map((member) => (
              <ListGroup.Item key={member._id}>{member.name}</ListGroup.Item>
            ))}
          </ListGroup>

          {/* Vote button using Button component */}
          <Button
            variant="primary"
            className="mt-4"
            onClick={handleVote}
          >
            Vote
          </Button>
        </div>
      )}

      {errorMessage && (
        <Alert variant="danger" className="mt-4">
          {errorMessage}
        </Alert>
      )}
    </div>
  );
};

export default Vote;
