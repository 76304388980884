import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, ListGroup, Button } from 'react-bootstrap';

function TransactionApproval() {
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  // Function to fetch pending transactions
  const fetchPendingTransactions = () => {
    // Make a GET request to fetch pending transactions from the backend
    axios
      .get('/transactions/pending')
      .then((response) => {
        setPendingTransactions(response.data.transactions);
      })
      .catch((error) => {
        console.error('Error fetching pending transactions:', error);
        setErrorMessage('Error fetching pending transactions. Please try again.');
      });
  };

  // Function to handle transaction approval
  const handleApproval = (transactionId) => {
    // Make a PUT request to approve the transaction
    axios
      .put(`/transactions/approve/${transactionId}`)
      .then((response) => {
        // Handle success, e.g., show a success message
        console.log('Transaction approved:', response.data);
        // Refetch pending transactions to update the list
        fetchPendingTransactions();
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error('Error approving transaction:', error);
        setErrorMessage('Error approving transaction. Please try again.');
      });
  };

  // Fetch pending transactions when the component mounts
  useEffect(() => {
    fetchPendingTransactions();
  }, []);

  return (
    <Container>
      <h1 className="text-2xl font-semibold mb-4">Pending Transactions</h1>
      {errorMessage && (
        <p className="text-red-500 mb-4">{errorMessage}</p>
      )}
      <ListGroup>
        {pendingTransactions.map((transaction) => (
          <ListGroup.Item key={transaction._id} className="d-flex justify-content-between align-items-center">
            <div>
              <p className="text-gray-700">Transaction Type: {transaction.type}</p>
              <p className="text-gray-700">Amount: {transaction.amount}</p>
              {/* Add more transaction details here */}
            </div>
            <Button
              variant="success"
              onClick={() => handleApproval(transaction._id)}
            >
              Approve
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
}

export default TransactionApproval;
