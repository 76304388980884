import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Container } from 'react-bootstrap';

const Groups = () => {
  // Define a state variable to store the list of groups
  const [groups, setGroups] = useState([]);

  // Use the useEffect hook to fetch groups from your Express backend
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        // Make a GET request to your backend API endpoint
        const response = await axios.get('/api/group');

        // Update the state with the fetched groups
        setGroups(response.data);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    // Call the fetchGroups function when the component mounts
    fetchGroups();
  }, []);

  return (
    <Container className="mt-4">
      {/* Render a title */}
      <h1 className="mb-4">Groups</h1>

      {/* Render the list of groups in a table */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Group Name</th>
            <th>Admin</th>
            <th>Members</th>
            <th>Group Code</th>
          </tr>
        </thead>
        <tbody>
          {groups.map((group) => (
            <tr key={group._id}>
              <td>{group.groupName}</td>
              <td>{group.admin.name}</td>
              <td>{group.members.length}</td>
              <td>{group.groupCode}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Groups;
