import React from 'react';

import GroupDetails from './GroupDetails';

const Landing = () => {
 

  return (
    <div >
     <GroupDetails/>
    </div>
  );
};

export default Landing;
