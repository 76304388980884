import React, { useState } from 'react';
import axios from 'axios';

const DesignateAuthorizedUsers = ({ group }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [message, setMessage] = useState('');

  const handleSelectUser = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleDesignate = async () => {
    try {
      const response = await axios.post('/api/groups/designate', {
        groupId: group._id,
        authorizedUsers: selectedUsers,
      });
      setMessage(response.data.message || 'Authorized users updated successfully');
    } catch (error) {
      setMessage(`Error updating authorized users: ${error.message}`);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-semibold mb-4">Designate Authorized Users</h2>
      <ul className="space-y-2">
        {group.members.map((member) => (
          <li key={member._id} className="border p-2 rounded-md flex justify-between items-center">
            <span>{member.name}</span>
            <input
              type="checkbox"
              checked={selectedUsers.includes(member._id)}
              onChange={() => handleSelectUser(member._id)}
            />
          </li>
        ))}
      </ul>
      <button
        className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 mt-4"
        onClick={handleDesignate}
      >
        Update Authorized Users
      </button>
      <p className="mt-2 text-center text-red-500">{message}</p>
    </div>
  );
};

export default DesignateAuthorizedUsers;
