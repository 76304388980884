import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';

function CreateGroupForm() {
  const navigate = useNavigate();
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [groupGoal, setGroupGoal] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/group', {
        groupName,
        groupDescription,
        groupGoal,
      });

      console.log(response.data);

      // Navigate to the created group's page (assuming response._id is the group ID)
      navigate(`/api/group/${response.data._id}`);
    } catch (error) {
      console.error('Group creation error:', error.response.data);
    }
  };

  return (
    <Container className="mt-4">
      <h2 className="mb-4">Create Group</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="groupName" className="mb-3">
          <Form.Label>Group Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter group name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="groupGoal" className="mb-3">
          <Form.Label>Group Goal</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter group goal"
            value={groupGoal}
            onChange={(e) => setGroupGoal(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="groupDescription" className="mb-3">
          <Form.Label>Group Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter group description"
            value={groupDescription}
            onChange={(e) => setGroupDescription(e.target.value)}
            required
          />
        </Form.Group>
        <Button type="submit" variant="primary">
          Create Group
        </Button>
      </Form>
    </Container>
  );
}

export default CreateGroupForm;
