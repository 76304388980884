import { useEffect, useState } from "react";
import { Container, ListGroup } from "react-bootstrap";
import axios from "axios";

function LoanList() {
  // State to store the list of loans
  const [loanList, setLoanList] = useState([]);

  // Fetch loans from the backend when the component mounts
  useEffect(() => {
    const fetchLoans = async () => {
      try {
        const response = await axios.get('/api/loans'); // Adjust endpoint as per your backend route
        setLoanList(response.data);
      } catch (error) {
        console.error('Error fetching loans:', error);
      }
    };

    fetchLoans();
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  return (
    <Container className="max-w-2xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Loan List</h1>
      {loanList.length === 0 ? (
        <p className="text-gray-600">No loans available.</p>
      ) : (
        <ListGroup>
          {loanList.map((loan) => (
            <ListGroup.Item key={loan._id}>
              <div className="d-flex align-items-center">
                <span className="text-lg font-semibold text-indigo-600">{loan.amount}</span>
                <span className="ml-2 text-gray-500">USD</span>
              </div>
              <p className="text-gray-600 mt-1">Interest Rate: {loan.interestRate}%</p>
              <p className="text-gray-600 mt-1">Repayment Date: {loan.repaymentDate}</p>
              {/* Add more loan details here */}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>
  );
}

export default LoanList;
