import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import axios from "axios";

function LoanRequestForm() {
  // State to manage form input values
  const [formData, setFormData] = useState({
    groupId: "",
    amount: "",
    interestRate: "",
    repaymentDate: "",
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/loans/request", formData); // Adjust endpoint as per your backend route
      console.log("Loan requested successfully:", response.data);
      // Add logic here to handle success, e.g., show a success message
    } catch (error) {
      console.error("Error requesting loan:", error);
      // Add logic here to handle error, e.g., show an error message
    }
  };

  return (
    <Container className="max-w-lg mx-auto">
      <Form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <Form.Group controlId="groupId">
          <Form.Label className="block text-gray-700 text-sm font-bold mb-2">Group ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Group ID"
            name="groupId"
            value={formData.groupId}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="amount">
          <Form.Label className="block text-gray-700 text-sm font-bold mb-2">Loan Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Loan Amount"
            name="amount"
            value={formData.amount}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="interestRate">
          <Form.Label className="block text-gray-700 text-sm font-bold mb-2">Interest Rate (%)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Interest Rate"
            name="interestRate"
            value={formData.interestRate}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="repaymentDate">
          <Form.Label className="block text-gray-700 text-sm font-bold mb-2">Repayment Date</Form.Label>
          <Form.Control
            type="date"
            placeholder="Select Repayment Date"
            name="repaymentDate"
            value={formData.repaymentDate}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Button
          type="submit"
          className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Request Loan
        </Button>
      </Form>
    </Container>
  );
}

export default LoanRequestForm;
