import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const PendingTransactions = () => {
  // Define state variables
  const [transactions, setTransactions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { groupId } = useParams();

  // Fetch pending transactions when the component mounts
  useEffect(() => {
    const fetchPendingTransactions = async () => {
      try {
        // Make a GET request to the `/api/groups/pending/:id` endpoint
        const response = await axios.get(`/api/group/pending/${groupId}`);

        // Update the state with the fetched pending transactions
        setTransactions(response.data.pendingTransactions);
      } catch (error) {
        console.error('Error fetching pending transactions:', error.response?.data);
        setErrorMessage('Error fetching pending transactions. Please try again.');
      }
    };

    // Call the fetchPendingTransactions function when the component mounts
    fetchPendingTransactions();
  }, [groupId]);

  return (
    <div>
      <h1 className="text-3xl font-bold">Pending Transactions</h1>
      <ul>
        {transactions.map((transaction) => (
          <li key={transaction._id}>
            {/* Display transaction details here */}
            <p>Transaction ID: {transaction._id}</p>
            {/* Add more transaction details as needed */}
          </li>
        ))}
      </ul>
      {errorMessage && (
        <p className="text-red-500 text-sm mt-4">{errorMessage}</p>
      )}
    </div>
  );
};

export default PendingTransactions;
