import AdminActions from "./component/AdminActions";
import ThemeSwitch from "./component/ThemeSwitch";

import HomeScreen from "./screens/HomeScreen";
import LoginForm from "./screens/LoginForm";
import RegisterForm from "./screens/RegisterForm";
import CreateGroupForm from "./screens/createGroupForm";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import GroupDetails from "./screens/GroupDetails";
import GroupMembersList from "./screens/GroupMembersList";
import TransactionRequests from "./screens/TransactionRequests";
import TransactionHistory from "./screens/TransactionHistory";
import DesignateAuthorizedUsers from "./component/AuthorisedUser";
import MomoIntegration from "./screens/MomoIntegration";
import Landing from "./screens/Landing";
import Groups from "./screens/Group";
import Vote from "./screens/VoteComponent";
import PendingTransactions from "./screens/PendingTransaction";
import TransactionApproval from "./screens/TransactionApproval";
import TransactionCollectFunds from "./screens/CollectFund";
import LoanRequestForm from "./screens/LoanRequestForm";
import LoanRepaymentForm from "./screens/LoanRepayment";
import LoanList from "./screens/LoanList";
import LoanDetail from "./screens/LoanDetail";
import LoanApprovalButton from "./screens/LoanApproval";
import { useContext, useState } from "react";
import { Store } from "./Store";
import GroupSearch from "./screens/SearchGroup";
import SingleGroup from "./screens/SingleGroup";
import Navbar from "./screens/Navbar";
import NitrogenScreen from "./screens/gauges/NitrogenScreen";
import PhosphorusScreen from "./screens/gauges/PhosphorusScreen";
import PotassiumScreen from "./screens/gauges/PotassiumScreen";
import TemperatureScreen from "./screens/gauges/TemperatureScreen";
import MoistureScreen from "./screens/gauges/MoistureScreen";
import PhScreen from "./screens/gauges/PhScreen";
import ChatDisplay from "./screens/ChatDisplay";

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  return (
    <div
      className={
        sidebarOpen
          ? "flex flex-col site-container active-cont"
          : "flex flex-col site-container "
      }
    >
      <BrowserRouter>
        <Routes>
          <Route path="/signup" element={<RegisterForm />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/" element={<HomeScreen />} />
          <Route path="/welcome" element={<Landing />} />
          <Route path="/n" element={<Navbar />} />
          <Route path="/search" element={<GroupSearch />} />
          <Route path="/details" element={<GroupDetails />} />
          <Route path="/member" element={<GroupMembersList />} />
          <Route path="/group" element={<CreateGroupForm />} />
          <Route path="/group/:id" element={<SingleGroup />} />
          <Route path="/list" element={<Groups />} />
          <Route path="/vote" element={<Vote />} />
          <Route path="/loan" element={<LoanRequestForm />} />
          <Route path="/repay" element={<LoanRepaymentForm />} />
          <Route path="/chat" element={<ChatDisplay />} />
          <Route path="/loanlist" element={<LoanList />} />
          <Route path="/loandetail" element={<LoanDetail />} />
          <Route path="/loanapprove" element={<LoanApprovalButton />} />
          <Route path="/designate" element={<DesignateAuthorizedUsers />} />
          <Route path="transaction" element={<TransactionHistory />} />
          <Route path="/pending" element={<PendingTransactions />} />
          <Route path="/nitrogen" element={<NitrogenScreen />} />
          <Route path="/phosphorus" element={<PhosphorusScreen />} />
          <Route path="/potassium" element={<PotassiumScreen />} />
          <Route path="/ph" element={<PhScreen />} />
          <Route path="/temperature" element={<TemperatureScreen />} />
          <Route path="/moisture" element={<MoistureScreen />} />
          <Route path="/pay" element={<MomoIntegration />} />
          <Route path="/request" element={<TransactionRequests />} />
          <Route path="/approve" element={<TransactionApproval />} />
          <Route path="/collect" element={<TransactionCollectFunds />} />
          <Route path="/nav" element={<ThemeSwitch />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
