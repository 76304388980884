import React, { useState } from 'react';
import { Form, Button, Alert, ListGroup } from 'react-bootstrap';
import axios from 'axios';

const GroupSearch = () => {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [joinGroupMessage, setJoinGroupMessage] = useState('');

  const handleSearch = async () => {
    try {
      // Send a GET request to search for groups based on the query
      const response = await axios.get(`/api/group/search?query=${query}`);
      setSearchResults(response.data.groups);
      setJoinGroupMessage('');
    } catch (error) {
      console.error('Error while searching for groups:', error);
      setSearchResults([]);
      setJoinGroupMessage('No matching groups found.');
    }
  };

  const handleJoinGroup = async (groupId) => {
    try {
      // Send a POST request to join the selected group
      const response = await axios.post('/api/group/join', { groupCode: groupId });
      setJoinGroupMessage(`Joined group "${response.data.group.groupName}" successfully.`);
    } catch (error) {
      console.error('Error while joining group:', error);
      setJoinGroupMessage('Failed to join the group.');
    }
  };

  return (
    <div>
      <h1 className="text-3xl font-bold">Group Search</h1>
      <Form.Group className="mb-4">
        <Form.Control
          type="text"
          placeholder="Search for a group..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Button
          variant="primary"
          className="ml-2"
          onClick={handleSearch}
        >
          Search
        </Button>
      </Form.Group>

      {joinGroupMessage && <Alert variant="success">{joinGroupMessage}</Alert>}

      {searchResults.length > 0 ? (
        <div>
          <h2 className="text-xl font-semibold">Search Results</h2>
          <ListGroup>
            {searchResults.map((group) => (
              <ListGroup.Item key={group._id} className="d-flex justify-content-between align-items-center">
                <span>{group.groupName}</span>
                <Button
                  variant="success"
                  onClick={() => handleJoinGroup(group.groupCode)}
                >
                  Join
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      ) : (
        <p>{joinGroupMessage || 'Enter a query to search for groups.'}</p>
      )}
    </div>
  );
};

export default GroupSearch;
