import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, ListGroup } from 'react-bootstrap';

function TransactionHistory({ group }) {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactionHistory = async () => {
      try {
        const response = await axios.get(`/api/transaction/group/${group._id}`);
        setTransactions(response.data);
      } catch (error) {
        console.error('Error when fetching transaction history', error.message);
      }
    };
    fetchTransactionHistory();
  }, [group]);

  return (
    <Container className="max-w-md mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-semibold mb-4">Transaction History</h2>
      <ListGroup className="space-y-2">
        {transactions.map((transaction) => (
          <ListGroup.Item key={transaction._id} className="d-flex justify-content-between align-items-center border rounded-md p-2">
            <span>{transaction.amount} {transaction.transactionType}</span>
            <span className="text-gray-500">{transaction.date}</span>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
}

export default TransactionHistory;
