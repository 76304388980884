import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import axios from "axios";

function LoanRepaymentForm() {
  // State to manage form input value
  const [repaymentAmount, setRepaymentAmount] = useState('');

  // Handle form input changes
  const handleInputChange = (e) => {
    const { value } = e.target;
    setRepaymentAmount(value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/loans/repay', { amount: repaymentAmount }); // Adjust endpoint as per your backend route
      console.log('Repayment successful:', response.data);
      // Add logic here to handle success, e.g., show a success message
    } catch (error) {
      console.error('Error repaying loan:', error);
      // Add logic here to handle error, e.g., show an error message
    }
  };

  return (
    <Container className="max-w-lg mx-auto">
      <Form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <Form.Group controlId="repaymentAmount">
          <Form.Label className="block text-gray-700 text-sm font-bold mb-2">
            Repayment Amount (USD)
          </Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Repayment Amount"
            value={repaymentAmount}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Button type="submit" className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
          Repay Loan
        </Button>
      </Form>
    </Container>
  );
}

export default LoanRepaymentForm;
