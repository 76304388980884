import React from 'react';
import { Button } from 'react-bootstrap';

function LoanApprovalButton({ loan, isGroupMember, onApprove }) {
  // Handle loan approval
  const handleApprove = () => {
    onApprove(loan._id); // Assuming onApprove is a function passed as a prop that takes the loan ID as an argument
  };

  return (
    <div>
      {isGroupMember && loan.status === 'Pending' && (
        <Button
          onClick={handleApprove}
          variant="primary"
          className="w-100"
        >
          Approve Loan
        </Button>
      )}
    </div>
  );
}

export default LoanApprovalButton;
