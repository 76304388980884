import { useState } from "react";
import axios from "axios";
import { Form, Button, Container } from "react-bootstrap";

function TransactionCollectFunds() {
  const [formData, setFormData] = useState({
    amount: 0,            // Amount to contribute
    phoneNumber: '',      // User's phone number
    externalId: '',       // External ID for tracking
  });

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Make a POST request to initiate fund collection to the backend
    axios
      .post('/transactions/collect', formData)
      .then((response) => {
        // Handle success, e.g., show a success message
        console.log('Fund collection initiated:', response.data);
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error('Error initiating fund collection:', error);
      });
  };

  return (
    <Container className="mt-4">
      <h1 className="mb-4">Collect Funds</h1>
      <Form onSubmit={handleSubmit}>
        {/* Amount input */}
        <Form.Group controlId="amount" className="mb-3">
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="number"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
          />
        </Form.Group>

        {/* User's phone number input */}
        <Form.Group controlId="phoneNumber" className="mb-3">
          <Form.Label>User's Phone Number</Form.Label>
          <Form.Control
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </Form.Group>

        {/* External ID input */}
        <Form.Group controlId="externalId" className="mb-3">
          <Form.Label>External ID</Form.Label>
          <Form.Control
            type="text"
            name="externalId"
            value={formData.externalId}
            onChange={handleChange}
          />
        </Form.Group>

        <Button type="submit" variant="primary">
          Initiate Collection
        </Button>
      </Form>
    </Container>
  );
}

export default TransactionCollectFunds;
