import React from 'react';
import { useTheme } from '../context/ThemeContext';


const ThemeSwitch = () => {
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <div className="flex items-center space-x-2">
      <label className="cursor-pointer">
        <input
          type="checkbox"
          checked={isDarkMode}
          onChange={toggleTheme}
          className="hidden"
        />
        <span className="bg-gray-400 w-12 h-6 rounded-full inline-block"></span>
        <span className={`bg-blue-500 w-6 h-6 rounded-full inline-block transition-transform duration-300 ${isDarkMode ? 'transform translate-x-6' : ''}`}></span>
      </label>
      <span className="text-sm">Toggle Dark Mode</span>
    </div>
  );
};

export default ThemeSwitch;
