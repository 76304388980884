import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import iot from "../photo/Iot3.jpg";
import comm1 from "../photo/comm1.jpg";
import npk from "../photo/NPK.jpg";
import s1 from "../photo/s1.jpg";
import fin from "../photo/fin2.jpg";
import team1 from "../photo/team1.jpeg";
import team2 from "../photo/team2.PNG";
import team3 from "../photo/team3.jpg";
import team4 from "../photo/team4.jpg";
import akimana from "../photo/akimana.jpeg"
import { Button, Card, Carousel, Col, Container, Row } from 'react-bootstrap';

const HomeScreen = () => {
  const navigate = useNavigate();

  return (
    <Container fluid className="p-4 bg-light">
      {/* Welcome Section */}
      <Row className="mb-4 text-center">
        <Col>
          <div className="intro-container">
            <h1 className="intro-title">Welcome to Financial Empowerment</h1>
            <h2 className="intro-title">toward Sustainable Agriculture</h2>
            <p className="intro-description">
              Discover accessible and community-driven financial solutions
            </p>
            <p>
            that foster economic growth and empower cooperative, 
            </p>
            <p>
              youth, women, and marginalized community initiatives across Africa.
            </p>
          <Button className="mt-3 mb-5" variant="primary" size="lg" onClick={() => navigate("/login")}>
            Get Started
          </Button>
          </div>
        </Col>
      </Row>

      {/* Activities Section */}
      <Row className="mb-4">
        <Col>
          <h2 className="text-center intro-title">Our Activities</h2>
        </Col>
      </Row>

      <Row className="mb-4" >
        <Col md={6} className="mb-4">
          <Card className="h-100 shadow-sm rotate-card" style={{margin:"2rem"}}>
            <Card.Img variant="top" src={fin} alt="Financial Management" className="rotate-img" />
            <Card.Body>
              <Card.Title>Financial Management</Card.Title>
              <Card.Text>
                We provide financial management services, including lease financing, to support farmers in scaling their operations.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-4">
          <Card className="h-100 shadow-sm rotate-card" style={{margin:"2rem"}}>
            <Card.Img variant="top" src={iot} alt="Technology Deployment" className="rotate-img" />
            <Card.Body>
              <Card.Title>Technology Deployment</Card.Title>
              <Card.Text>
                We deploy state-of-the-art technology including IoT devices and AI-driven solutions to optimize agricultural practices.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6} className="mb-4">
          <Card className="h-100 shadow-sm rotate-card" style={{margin:"2rem"}}>
            <Card.Img variant="top" src={comm1} alt="Farmer Training" className="rotate-img" />
            <Card.Body>
              <Card.Title>Farmer Training</Card.Title>
              <Card.Text>
                Our experts provide comprehensive training to farmers on modern farming techniques and sustainable practices.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-4">
          <Card className="h-100 shadow-sm rotate-card" style={{margin:"2rem"}}>
            <Card.Img variant="top" src={npk} alt="Data Analytics" className="rotate-img" />
            <Card.Body>
              <Card.Title>Data Analytics</Card.Title>
              <Card.Text>
                We use data analytics and AI to provide actionable insights, helping farmers make informed decisions to improve yields.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={{ span: 6, offset: 3 }} className="mb-4">
          <Card className="h-100 shadow-sm rotate-card" style={{margin:"2rem"}}>
            <Card.Img variant="top" src={s1} alt="Sustainability Practices" className="rotate-img" />
            <Card.Body>
              <Card.Title>Sustainability Practices</Card.Title>
              <Card.Text>
                Our projects are designed with a focus on environmental sustainability, promoting practices that protect and enhance natural resources.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

        {/* Team Section */}
     <Row className="mb-4">
    <Col>
      <h2 className="text-center">Meet Our Team</h2>
    </Col>
  </Row>
  <Row className="mb-4">
    <Col>
      <Carousel indicators={false} interval={3000}>
        <Carousel.Item>
          <Row className="justify-content-center">
            <Col md={4}>
              <Card className="team-card text-center">
                <Card.Img variant="top" src={team1} style={{width:"50%",height:"auto",marginLeft:"150px"}} alt="Team Member 2" />
                <Card.Body>
                  <Card.Title>Gilbert HATANGIMANA</Card.Title>
                  <Card.Text>CEO- Co-Founder <br/>MSc in Internet Of things <br/>years experience as a business analyst</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="team-card text-center">
                <Card.Img style={{width:"50%",height:"auto",marginLeft:"150px"}} variant="top" src={team2} alt="Team Member 1" />
                <Card.Body>
                  <Card.Title>Divine Douce INEZA</Card.Title>
                  <Card.Text>CTO<br/>BSc Horticulture</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="justify-content-center">
            <Col md={4}>
              <Card className="team-card text-center">
                <Card.Img style={{width:"50%",height:"auto", marginLeft:"150px"}} variant="top" src={team4} alt="Team Member 3" />
                <Card.Body>
                  <Card.Title>Andrew Kwizera</Card.Title>
                  <Card.Text>Agronomist<br/>BSc in Agronomy</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="team-card text-center">
                <Card.Img style={{width:"50%",height:"auto", marginLeft:"150px"}} variant="top" src={akimana} alt="Team Member 4" />
                <Card.Body>
                  <Card.Title>Cynthia AKIMANA</Card.Title>
                  <Card.Text>Data Analysts<br/>Bsc in Statistics</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className='justify-content-center'>
            <Col md={4}>
             <Card className="team-card text-center">
                <Card.Img style={{width:"50%",height:"auto", marginLeft:"150px"}} variant="top" src={team3} alt="Team Member 4" />
                <Card.Body>
                  <Card.Title>John TUOMBE</Card.Title>
                  <Card.Text>Founder<br/>AI Engineer</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </Col>
  </Row>

      {/* Footer */}
      <footer className="bg-light text-dark text-center p-4 mt-4">
        <Container>
          <Row>
            <Col>
              <h5>Contact Information</h5>
              <p>Email: info@hingawise.com</p>
              <p>Phone: +250 785 991 560 / +250 780 486 359</p>
              <p>Address: 123 Ave, Kigali, Rwanda</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </Container>
  );
};

export default HomeScreen;
