import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { Store } from '../Store';

const SingleGroup = () => {
  const { id } = useParams();
  const { state } = useContext(Store);
  const { userInfo } = state;

  const [group, setGroup] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [votingInitiated, setVotingInitiated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        // Fetch the group by its ID
        const response = await axios.get(`/api/group/${id}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` }
        });

        const groupData = response.data.group;

        setGroup(groupData);

        // Check if the user is a member of the group
        const userId = userInfo._id;

        setIsMember(groupData.members.includes(userId));

        // Check if voting is initiated (you may need to implement this logic on the server)
        setVotingInitiated(groupData.votingInitiated);
      } catch (error) {
        console.error('Error fetching group:', error);
        setErrorMessage('Error fetching group details. Please try again.');
      }
    };

    // Call the fetchGroup function when the component mounts
    fetchGroup();
  }, [id, userInfo]);

  const handleVote = async () => {
    try {
      const userId = userInfo._id;

      // Check if the user has already voted
      if (group.votes.some((vote) => vote.voter === userId)) {
        // You can show a message or handle it as needed (e.g., displaying an error message)
        console.log('You have already voted');
        return;
      }

      // Send a POST request to the backend to record the vote
      const response = await axios.post(`/api/group/${id}/vote`);

      // Assuming the response contains updated group data, you can update the state
      // to reflect the changes (if needed) by fetching the group details again
      const updatedGroupData = response.data.group;
      setGroup(updatedGroupData);

      // Optionally, you can show a success message or perform other actions
      console.log('Vote recorded successfully');
    } catch (error) {
      console.error('Error while voting:', error);
      setErrorMessage('Failed to record vote. Please try again.');
    }
  };

  return (
    <Container>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      {group ? (
        <div>
          <Row className="mb-4">
            <Col>
              <h1 className="text-3xl font-bold">{group.groupName}</h1>
              <p>Admin: {group.admin.name}</p>
              <p>Group Code: {group.groupCode}</p>
            </Col>
            <Col className="d-flex align-items-center">
              <h5 className="ml-4">Members: {group.members.length}</h5>
            </Col>
          </Row>
          {isMember && votingInitiated && (
            <Button
              variant="primary"
              onClick={handleVote}
              className="mt-4"
            >
              Vote
            </Button>
          )}
        </div>
      ) : (
        <p>Loading group details...</p>
      )}
    </Container>
  );
};

export default SingleGroup;
