import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Store } from '../Store';

function Navbar() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const logoutHandler = () => {
    ctxDispatch({ type: "LOGOUT" });
    localStorage.removeItem("userInfo");
    window.location.href = '/';
  };

  return (
    <nav className="bg-blue-500 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-white text-xl font-bold">
          Your Logo
        </Link>
        <ul className="flex space-x-4">
          <li>
            <Link to="/" className="text-white hover:text-blue-200">
              Home
            </Link>
          </li>
          <li>
            <Link to="/products" className="text-white hover:text-blue-200">
              Products
            </Link>
          </li>
          <li>
            <Link to="/cart" className="text-white hover:text-blue-200">
              Cart
            </Link>
          </li>
          {/* Add more navigation links as needed */}
        </ul>
        {/* You can add user-related elements here */}
        {/* For example, display user name and signout button */}
        {userInfo ? (
          <div className="text-white">
            <p>Hello, {userInfo.name}</p>
            <button className="text-white hover:text-blue-200" onClick={logoutHandler}>
              Sign Out
            </button>
          </div>
        ) : (
          <Link to="/signin" className="text-white hover:text-blue-200">
            Sign In
          </Link>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
