import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import axios from "axios";

function LoanDetail() {
  const { loanId } = useParams(); // Get loanId from the route parameters
  const [loan, setLoan] = useState(null);

  // Fetch loan details from the backend when the component mounts
  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        const response = await axios.get(`/api/loan/${loanId}`);
        setLoan(response.data);
      } catch (error) {
        console.error('Error fetching loan details:', error);
      }
    };

    fetchLoanDetails();
  }, [loanId]);

  // Handle loan repayment
  const handleRepayLoan = () => {
    // Add logic here to allow the user to repay the loan
  };

  return (
    <div className="container">
      {loan ? (
        <>
          <h1 className="text-2xl font-bold mb-4">Loan Details</h1>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title className="text-primary">{loan.amount} USD</Card.Title>
              <Card.Text>
                <strong>Interest Rate:</strong> {loan.interestRate}%
              </Card.Text>
              <Card.Text>
                <strong>Repayment Date:</strong> {loan.repaymentDate}
              </Card.Text>
              {/* Display more loan details here */}
              {loan.status === 'Pending' && (
                <Button
                  onClick={handleRepayLoan}
                  variant="primary"
                  className="w-100"
                >
                  Repay Loan
                </Button>
              )}
            </Card.Body>
          </Card>
        </>
      ) : (
        <p>Loading loan details...</p>
      )}
    </div>
  );
}

export default LoanDetail;
