import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Chart from 'react-google-charts';

const NitrogenScreen = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch('http://hatangaiot.atwebpages.com/nitrogen.php')
      .then((response) => response.json())
      .then((apiData) => setData(apiData))
      .catch((error) => console.error('Error fetching data:', error));
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const chartData = [['Time of Insertion', 'Nitrogen']]; // Header for the chart data
  data.forEach((item) => {
    chartData.push([item.time_of_insertion, parseFloat(item.nitrogen)]);
  });

  return (
    <div>
      <Row>
        <Col>
          <h2>Nitrogen Readings Chart</h2>
          <Chart
          
            height={'800px'}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={chartData}
            options={{
              hAxis: {
                title: 'Time of Insertion',
              },
              vAxis: {
                title: 'Nitrogen',
              },
              legend: 'none',
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default NitrogenScreen;
