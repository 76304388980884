import React, { useEffect, useState } from 'react';
 
import axios from 'axios';
import { Link } from 'react-router-dom';

const GroupDetails = ({ group }) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    // Fetch the list of joined groups from the server
    const fetchGroups = async () => {
      try {
        const response = await axios.get('/api/group'); // Change the endpoint
        console.log(response);
        setGroups(response.data);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    fetchGroups();
  }, []);
  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded-md">
       <h1 >My Groups</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {groups.map((group) => (
          <Link
            to={`/group/${group._id}`} // Adjust the route as per your app's setup
            key={group._id}
            className="bg-white p-4 rounded shadow hover:shadow-md transition duration-300"
          >
            <h2 className="text-lg font-semibold mb-2">{group.groupName}</h2>
            <p className="text-gray-600">{group.description}</p>
            {group.isJoinable && (
              <button className="bg-blue-500 text-white px-3 py-1 mt-2 rounded">
                Join
              </button>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default GroupDetails;
