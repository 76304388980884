import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

const GroupMembersList = ({ group }) => {
  return (
    <ListGroup>
      {group.members.map((member) => (
        <ListGroupItem key={member._id} className="d-flex justify-content-between align-items-center">
          <span>{member.name}</span>
          <span className="text-muted">{member.email}</span>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

export default GroupMembersList;
